import styled from "styled-components"

const MenuItem = styled.div`
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;

  &:hover {
    border-bottom: 3px solid whitesmoke;
    margin-bottom: -6px;
  }
`

export default MenuItem
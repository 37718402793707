import React, {useState, useEffect} from "react"
import {graphql, useStaticQuery} from "gatsby"
import {Link} from "gatsby"

import NavbarWrapper from "../styled-components/navbar/NavbarWrapper"
import NavbarLogo from "../styled-components/navbar/NavbarLogo"
import NavbarContainer from "../styled-components/navbar/NavbarContainer"
import Menu from "../styled-components/navbar/Menu"
import MenuItem from "../styled-components/navbar/MenuItem"
import MenuLink from "../styled-components/navbar/MenuLink"

const Navbar = () => {

    const data = useStaticQuery(graphql`
    {
      allStrapiBlackowlNavbars {
        nodes {
          logo {
            url
          }
          Pages {
            id
            link
            name
          }
        }
      }
    }
  `)

    const {allStrapiBlackowlNavbars: {nodes}} = data

    const [scrollDir, setScrollDir] = useState("")

    useEffect(() => {
        const threshold = 5
        let lastScrollY = window.pageYOffset
        let ticking = false

        const updateScrollDir = () => {
            const scrollY = window.pageYOffset

            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false
                return
            }
            setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up")
            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false
        }

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir)
                ticking = true
            }
        }
        window.addEventListener("scroll", onScroll)
        if (scrollDir === "scrolling up") {
            document.getElementById("headerNavigation").style.backgroundColor = "#083852"
            document.getElementById("headerNavigation").style.marginTop = "0px"
        } else if (scrollDir === "scrolling down") {
            document.getElementById("headerNavigation").style.marginTop = "-105px"
        }
        return () => window.removeEventListener("scroll", onScroll)

    }, [scrollDir]);

    return (
        <NavbarWrapper id="headerNavigation">
            <NavbarContainer>
                <Link to="/">
                    <NavbarLogo src={nodes[0].logo.url} alt="Balasys Logo"/>
                </Link>
                <Menu>
                    {nodes[0].Pages.map((item) => (
                        <MenuItem key={item.id}>
                            <Link to={item.link}><MenuLink>{item.name}</MenuLink></Link>
                        </MenuItem>
                    ))}
                </Menu>
            </NavbarContainer>
        </NavbarWrapper>
    )
}

export default Navbar;

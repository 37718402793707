import React from "react"

import Navbar from "../main/Navbar"
import Footer from "../main/Footer"
import Sidebar from "../main/Sidebar"

import H1 from "../styled-components/typography/H1"
import Background from "../styled-components/navbar/Background"

const Layout = ({children, title, template}) => {

    return (
        <>
            <Navbar/>
            <Sidebar/>
            {title &&
            <Background template={template}>
                <H1>{title}</H1>
            </Background>
            }
            {children}
            <Footer/>
        </>
    )
}

export default Layout
import styled from "styled-components"

const P = styled.p`
  margin-bottom: ${props => props.noMargin ? "0px" : "24px"};
  color: #706b81;
  align-self: ${props => props.center ? "center" : "unset"};
  ${props => {
    if (props.position) {
      return `
      font-size: 17px;
      line-height: 28px;
  `
    } else if (props.description) {
      return `
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 0px;
      @media screen and (max-width: 800px) {
          margin-bottom: 10px;
      }
  `
    } else if (props.italic) {
      return `
      font-size: 14px;
      line-height: 20px;
      font-style: italic;
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 10px;
  `
    } else if (props.events) {
      return `
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 0px;
      align-self: center;
      color: #1a1725;
      &:hover{
        text-decoration: underline;
      }
  `
    } else if (props.eventAvatar) {
      return `
      font-size: 17px;
      line-height: 20px;
      font-weight: 700;
      color: black;
      margin-bottom: 0px;
      @media screen and (max-width: 800px) {
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 5px;
      }
  `
    }
    else if (props.eventPosition) {
      return `
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 0px;
  `
    } else if (props.time) {
      return `
      font-family: Archivo, sans-serif;
      color: #1a1725;
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 0px;
      margin-left: 15px;
  `
    } else if (props.scheduleTime) {
      return `
      font-family: Archivo, sans-serif;
      color: #1a1725;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 0px;
      @media screen and (max-width: 800px) {
          font-size: 16px;
          line-height: 18px;
          margin-bottom: 5px;
      }
  `
    }
  }
  }
`

export default P
import styled from "styled-components";

const H1 = styled.h1`
  color: white;
  font-size: 52px;
  line-height: 64px;
  font-weight: 700;
  justify-self: center;
  align-self: center;
  font-family: Archivo, sans-serif;
  width: 100%;
  @media only screen and (max-width: 1100px) {
    font-size: 42px;
    line-height: 48px;
    font-weight: 700;
  }
  @media only screen and (max-width: 800px) {
    font-size: 26px;
    line-height: 36px;
    font-weight: 600;
  }
`

export default H1
import styled from "styled-components";

const SectionLayoutGrid = styled.div`
  display: grid;
  width: 70vw;
  margin: auto;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: ${props => props.smallGap ? "10px" : "40px"};
  padding-bottom: 5vh;
  padding-top: 5vh;
  min-height: ${props => props.register ? "75vh" : "40vh"};
  align-content: ${props => props.alignStart ? "start" : "center"};
  justify-items: center;
  @media screen and (max-width: 800px) {
    height: auto;
    width: 80vw;
    grid-row-gap: 5px;
    grid-template-columns: 1fr;
  }
`

export default SectionLayoutGrid
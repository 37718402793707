import styled from "styled-components";

const Background = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 10%;
  text-align: left;
  background-repeat: no-repeat;
  background-position: 0px 0px, 50% 50%;
  background-color: white;
  background-size: auto, cover;
  background-image: linear-gradient(180deg, rgba(11, 31, 44, 0.76), rgba(11, 31, 44, 0.76)), url('/assets/header-img.jpg');
  height: ${props => props.template ? "45vh" : "40vh"} !important;
  @media screen and (max-width: 800px) {
    height: 30vh !important;
  }
`

export default Background
import styled from "styled-components"

const NavbarContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 9fr;
  max-width: 80%;
  margin: auto;
  height: 100px;
  align-items: center;
`

export default NavbarContainer
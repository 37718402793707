import React from 'react';

import Layout from "../components/layouts/Layout";
import Seo from "../components/main/SEO";

import SectionLayoutGrid from "../components/styled-components/wrappers/SectionLayoutGrid";
import Col from "../components/styled-components/wrappers/Col";
import H2 from "../components/styled-components/typography/H2";
import P from "../components/styled-components/typography/P";
import LinkButton from "../components/styled-components/button/LinkButton";

const ThankYou = () => {
    return (
        <Layout title={"Sikeres regisztráció"}>
            <Seo title={"Balasys Blackowl - Köszönjük"} description={"A Balasys Blackowl köszönő oldala."} keywords={"balasys, blackowl, köszönjük"} url={"/thank-you"}/>
            <SectionLayoutGrid id="thank-you">
                <Col full grid center>
                    <H2>
                        Köszönjük a regisztrációt, jó szórakozást kívánunk a meetuphoz, aminek a linkjét egy automatikus emailben – feladója Balogh Eszter – küldjük el neked hamarosan!
                    </H2>
                    <P>
                        Ha vissza szeretnél térni a kezdőlapra, kérjük, kattints a ‘Vissza’ gombra
                    </P>
                </Col>
                <Col full center>
                    <LinkButton to={"/"}>
                        Vissza
                    </LinkButton>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    );
};

export default ThankYou;
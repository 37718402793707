import styled from "styled-components";

const NavbarWrapper = styled.nav`
  background: transparent;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
  width: 100%;
  margin-top: 0px;
  transition: margin-top 0.2s ease-out;
  position: fixed;
  z-index: 100;
  @media screen and (max-width: 800px){
    display: none;
  }
`

export default NavbarWrapper
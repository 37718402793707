import React from "react"
import styled from "styled-components"
import {Link} from "gatsby"

import Col from "../styled-components/wrappers/Col"

const FooterBackground = styled.footer`
  background: #083852;
`

const FooterWrapper = styled.div`
  display: grid;
  width: 70vw;
  margin: auto;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 1vw;
  align-items: center;
  height: 10vh;
  @media screen and (max-width: 800px) {
    height: auto;
    width: 90%;
    grid-row-gap: 5px;
    grid-template-columns: 1fr;
    text-align: center;
  }
`

const FooterIMG = styled.img`
  margin-top: 25px;
  margin-bottom: 25px;
  height: 50px;
  @media screen and (max-width: 800px) {
    margin-top: 10px;
    margin-bottom: 5px;
    height: 40px;
    align-self: center;
  }
`

const FooterP = styled.p`
  color: white;
  text-align: center;
  @media screen and (max-width: 800px) {
    margin-bottom: 10px;
  }
`

const FooterA = styled.a`
  color: white;
  &:hover{
    opacity: 0.7;
  }
`

const Footer = () => {

    const date = new Date().getFullYear()

    return (
        <FooterBackground>
            <FooterWrapper>
                <Col col3>
                    <Link to={"/"}>
                        <FooterIMG src="/assets/black_owl_logo.svg" alt="Blackowl logo"/>
                    </Link>
                </Col>
                <Col col6>
                    <FooterP>Copyright © {date} <FooterA href="https://balasys.hu/" target="_blank" rel="noreferrer">Balasys</FooterA> IT Zrt.</FooterP>
                </Col>
                <Col col3>
                    <FooterA href="https://balasys.hu/hu/privacy-policy" target="_blank" rel="noreferrer"><FooterP>Adatvédelmi nyilatkozat.</FooterP></FooterA>
                </Col>
            </FooterWrapper>
        </FooterBackground>
    )
}

export default Footer
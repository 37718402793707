import React, {useState} from 'react'
import {graphql, useStaticQuery, Link} from "gatsby"
import styled from "styled-components";

const SidebarContainer = styled.nav`
  display: none;
  @media screen and (max-width: 800px) {
    display: grid;
  }
`

const SidebarImg = styled.img`
  position: fixed;
  z-index: 1000;
  top: 20px;
  left: 5%;
  cursor: pointer;
  transition: display 2s;
  transition-delay: 2s;
  height: 40px;
  display: block;
`

const MenuButtonWrapper = styled.div`
  display: none;
  @media screen and (max-width: 800px) {
    display: block;
  }
`

const HamburgerIconWrapper = styled.div`
  position: fixed;
  z-index: 1001;
  top: 38px;
  left: 15px;
  width: 20px;
  height: 20px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    display: block;
    top: 30px;
    left: 85%;
  }
`

const HamburgerIcon = styled.span`
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: white;
  border-radius: 3px;
  opacity: 1;
  border: 1px solid black;
  left: 0;
  transition: 0.25s ease-in-out;
  ${props => {
    if (props.first) {
      return `
        top: 0px;
    `
    } else if (props.mid) {
      return `
        top: 6px;
    `
    } else if (props.last) {
      return `
        top: 12px;
    `
    } else if (props.open1) {
      return `
        top: 12px;
        width: 0%;
        left: 50%;
        border: none;
    `
    } else if (props.open2) {
      return `
        top: 6px;
        transform: rotate(45deg) !important;
        background: #083852 !important;
        border: none;
        height: 3px;
    `
    } else if (props.open3) {
      return `
        top: 6px;
        transform: rotate(-45deg) !important;
        background: #083852 !important;
        border: none;
        height: 3px;
    `
    } else if (props.open4) {
      return `
        top: 18px;
        width: 0%;
        left: 50%;
        border: none;
    `
    }
  }}
`

const SidebarBox = styled.div`
  align-content: start;
  grid-auto-flow: row;
  padding-top: 80px;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  margin-left: -100%;
  display: grid;
  opacity: 0;
  ${props => {
    if (props.open) {
      return `
        opacity: 1;
        animation-name: MenuOpen;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-out;
        @keyframes MenuOpen {
            0% {
                margin-left: -100%;
            }
            100% {
                margin-left: 0%;
            }
        }
    `
    } else if (props.close) {
      return `
        opacity: 1;
        animation-name: MenuClose;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in;
        @keyframes MenuClose {
            0% {
                margin-left: 0%;
            }
            100% {
                margin-left: -100%;
            }
        }
    `
    }
  }}
`

const MenuLink = styled(Link)`
  color: #083852;
  font-weight: normal;
  text-decoration: none;
  line-height: 40px;
  font-size: 16px;
  display: grid;
  grid-auto-flow: column;
  text-align: center;
`


const Sidebar = () => {

    const data = useStaticQuery(graphql`
    {
      allStrapiBlackowlNavbars {
        nodes {
          logo {
            url
          }
          Pages {
            id
            link
            name
          }
        }
      }
    }
  `)

    const {allStrapiBlackowlNavbars: {nodes}} = data

    const [isSidebar, setIsSidebar] = useState(false)
    const [counter, setCounter] = useState(0)

    function menuOpen() {
        setIsSidebar(!isSidebar)
        setCounter(counter + 1)
    }

    return (
        <SidebarContainer>
            <Link to={"/"}><SidebarImg src="/assets/black_owl_logo.svg" alt="logo"/></Link>
            <MenuButtonWrapper onClick={() => menuOpen()} aria-hidden={"true"}>
                <HamburgerIconWrapper>
                    <HamburgerIcon first={!isSidebar} open1={isSidebar}/>
                    <HamburgerIcon mid={!isSidebar} open2={isSidebar}/>
                    <HamburgerIcon mid={!isSidebar} open3={isSidebar}/>
                    <HamburgerIcon last={!isSidebar} open4={isSidebar}/>
                </HamburgerIconWrapper>
            </MenuButtonWrapper>
            <SidebarBox open={isSidebar} close={counter !== 0 && !isSidebar}>
                {nodes[0].Pages.map((item,index) => (
                    <MenuLink key={index} to={item.link}>{item.name}</MenuLink>
                ))}

            </SidebarBox>
        </SidebarContainer>
    )
}

export default Sidebar
import styled from "styled-components"

const MenuLink = styled.h2`
  color: white;
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 90px;
`

export default MenuLink
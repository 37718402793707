import styled from "styled-components";

const handleMarginBottom = marginBottom => {
  switch (marginBottom) {
    case "small":
      return "50px";
    case "medium":
      return "100px";
    case "large":
      return "150px";
    default:
      return "0px";
  }
};

const Col = styled.div`
  text-align: ${props => props.center ? "center" : ""};
  justify-self: ${props => props.center ? "center" : "start"};
  display: ${props => props.grid ? "grid" : ""};
  margin-bottom: ${({ marginBottom }) => handleMarginBottom(marginBottom)};
  margin-top: ${({ marginTop }) => handleMarginBottom(marginTop)};
  margin-top: ${props => props.top && "50px"};
  width: 100%;
  @media screen and (max-width: 800px) {
    grid-column: 1/-1 !important;
  }
  ${props => {
    if (props.full) {
      return `
        grid-column: 1/-1 !important;
        @media screen and (max-width: 800px) {
          grid-column: 1/-1 !important;
        }
    `
    } else if (props.col1) {
      return `
        grid-column: span 1 !important;
        @media screen and (max-width: 800px) {
          grid-column: 1/-1 !important;
        }
    `
    } else if (props.col2) {
      return `
        grid-column: span 2 !important;
        @media screen and (max-width: 800px) {
          grid-column: 1/-1 !important;
        }
    `
    } else if (props.col3) {
      return `
        grid-column: span 3 !important;
        @media screen and (max-width: 800px) {
          grid-column: 1/-1 !important;
        }
    `
    } else if (props.col4) {
      return `
        grid-column: span 4 !important;
        @media screen and (max-width: 800px) {
          grid-column: 1/-1 !important;
        }
    `
    } else if (props.col5) {
      return `
        grid-column: span 5 !important;
        @media screen and (max-width: 800px) {
          grid-column: 1/-1 !important;
        }
    `
    } else if (props.col6) {
      return `
        grid-column: span 6 !important;
        @media screen and (max-width: 800px) {
          grid-column: 1/-1 !important;
        }
    `
    } else if (props.col7) {
      return `
        grid-column: span 7 !important;
        @media screen and (max-width: 800px) {
          grid-column: 1/-1 !important;
        }
    `
    } else if (props.col8) {
      return `
        grid-column: span 8 !important;
        @media screen and (max-width: 800px) {
          grid-column: 1/-1 !important;
        }
    `
    } else if (props.col9) {
      return `
        grid-column: span 9 !important;
        @media screen and (max-width: 800px) {
          grid-column: 1/-1 !important;
        }
    `
    } else if (props.col10) {
      return `
        grid-column: span 10 !important;
        @media screen and (max-width: 800px) {
          grid-column: 1/-1 !important;
        }
    `
    } else if (props.col11) {
      return `
        grid-column: span 11 !important;
        @media screen and (max-width: 800px) {
          grid-column: 1/-1 !important;
        }
    `
    } else if (props.events) {
      return `
        grid-column: span 4 !important;
        @media screen and (max-width: 1450px) {
          grid-column: span 6 !important;
        }
        @media screen and (max-width: 800px) {
          grid-column: 1/-1 !important;
        }
    `
    }
  }}
`

export default Col;
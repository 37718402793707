import styled from "styled-components"

const H2 = styled.h2`
  margin-top: 0px;
  margin-bottom: ${props => props.noMargin ? "0px" : "16px"};
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #1a1725;
  &:hover{
    text-decoration: ${props => props.underline ? "underline" : ""};
  }
  ${props => {
    if (props.small) {
      return `
      font-size: 26px;
      line-height: 32px;
      @media only screen and (max-width: 1100px) {
        font-size: 18px;
        line-height: 24px;
      }
      @media only screen and (max-width: 800px) {
        font-size: 16px;
        line-height: 18px;
      }
  `
    } else if (props.medium) {
      return `
      font-size: 48px;
      line-height: 56px;
      @media only screen and (max-width: 1100px) {
        font-size: 36px;
        line-height: 42px;
      }
      @media only screen and (max-width: 800px) {
        font-size: 26px;
        line-height: 28px;
      }
  `
    } else if (props.large) {
      return `
      font-size: 48px;
      line-height: 56px;
      font-weight: 700;
      @media only screen and (max-width: 1100px) {
        font-size: 36px;
        line-height: 42px;
      }
      @media only screen and (max-width: 800px) {
        font-size: 28px;
        line-height: 36px;
        font-weight: 600;
      }
  `
    }
  }}
`

export default H2